const TIMEOUT = 30000;
//const BASE_URI = 'https://localhost:7028/api/';
const BASE_URI = 'https://appmanagerbackend.azurewebsites.net/api/';
//const BASE_URI = 'https://api.wartun.com/api/';

class Api {

    //#region User & Authentication

    async postLogin(loginData) {
        let parameters = { username: loginData.username, password: loginData.password, language: 'es-VE' };
        const result = await fetchWithTimeout(`${BASE_URI}Authenticate/login`, {
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { "Content-Type": "application/json" },
        })
        return result;
    }

    async getUser(access_token, params) {

        let url = BASE_URI + "Users/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async getInvitation(params) {
        let url = BASE_URI + "Users/getInvitation?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async postConfirmInvitation(params) {
        let parameters = {
            id: params.id, firstName: params.firstName,
            lastName: params.lastName, email: params.email,
            password: params.password, token: params.token,
            tenantId: params.tenantId
        };

        const result = await fetchWithTimeout(`${BASE_URI}Users/ConfirmInvitation`, {
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { "Content-Type": "application/json" },
        })
        return result;
    }

    async postForgotPassword(params) {
        let parameters = { email: params.email };

        const result = await fetchWithTimeout(`${BASE_URI}Authenticate/ForgotPassword`, {
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { "Content-Type": "application/json" },
        })
        return result;
    }

    async postResetPassword(params) {
        let parameters = { id: params.id, email: params.email, token: params.token, password: params.password };

        const result = await fetchWithTimeout(`${BASE_URI}Authenticate/ResetPassword`, {
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: { "Content-Type": "application/json" },
        })
        return result;
    }

    async resendInvitation(access_token, body) {

        let url = BASE_URI + "Users/resendInvitation";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listUsers(access_token, body) {

        let url = BASE_URI + "Users/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createUser(access_token, body) {

        let url = BASE_URI + "Users/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editUser(access_token, body) {

        let url = BASE_URI + "Users/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteUser(access_token, body) {

        let url = BASE_URI + "Users/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region Tenant

    async getTenant(access_token, params) {

        let url = BASE_URI + "Tenant/get?Id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editTenant(access_token, body) {

        let url = BASE_URI + "Tenant/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region Dashboard

    async getDashboard(access_token, params) {

        let url = BASE_URI + "Dashboard/get?tenantId=" + params.tenantId;

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async getDashboardTasksData(access_token, params) {

        let url = BASE_URI + "Dashboard/getDashboardTasksData?tenantId=" + params.tenantId;

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async getDashboardServiceTasksData(access_token, params) {

        let url = BASE_URI + "Dashboard/getDashboardServiceTasksData?tenantId=" + params.tenantId;

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async getTenantStatistics(access_token, params) {
        console.log("GetTenantStatistics API", params);
        let url = BASE_URI + "Dashboard/getTenantStatistics";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(params),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region MaintenanceTasks

    async getMaintenanceTask(access_token, params) {

        let url = BASE_URI + "MaintenanceTask/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listMaintenanceTasks(access_token, body) {

        let url = BASE_URI + "MaintenanceTask/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createMaintenanceTask(access_token, body) {

        let url = BASE_URI + "MaintenanceTask/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editMaintenanceTask(access_token, body) {

        let url = BASE_URI + "MaintenanceTask/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteMaintenanceTask(access_token, body) {

        let url = BASE_URI + "MaintenanceTask/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region WorkOrder

    async getWorkOrder(access_token, params) {

        let url = BASE_URI + "workOrder/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listWorkOrders(access_token, body) {

        let url = BASE_URI + "workOrder/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createWorkOrder(access_token, body) {

        let url = BASE_URI + "workOrder/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async sendToValidateWorkOrder(access_token, body) {

        let url = BASE_URI + "workOrder/sendToValidate";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async finishWorkOrder(access_token, body) {

        let url = BASE_URI + "workOrder/finishWorkOrder";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async addWorkOrderDetail(access_token, body) {
        let url = BASE_URI + "workOrder/addWorkOrderDetail";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editWorkOrder(access_token, body) {

        let url = BASE_URI + "workOrder/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region ServiceTasks

    async getServiceTask(access_token, params) {

        let url = BASE_URI + "ServiceTask/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listServiceTasks(access_token, body) {

        let url = BASE_URI + "ServiceTask/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createServiceTask(access_token, body) {

        let url = BASE_URI + "ServiceTask/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editServiceTask(access_token, body) {

        let url = BASE_URI + "ServiceTask/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteServiceTask(access_token, body) {

        let url = BASE_URI + "ServiceTask/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region ServiceWorkOrder

    async getServiceWorkOrder(access_token, params) {

        let url = BASE_URI + "serviceWorkOrder/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listServiceWorkOrders(access_token, body) {

        let url = BASE_URI + "serviceWorkOrder/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createServiceWorkOrder(access_token, body) {

        let url = BASE_URI + "serviceWorkOrder/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async finishServiceWorkOrder(access_token, body) {

        let url = BASE_URI + "serviceWorkOrder/finish";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async addServiceWorkOrderDetail(access_token, body) {
        let url = BASE_URI + "serviceWorkOrder/addDetail";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editServiceWorkOrder(access_token, body) {

        let url = BASE_URI + "serviceWorkOrder/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region Assets

    async getAsset(access_token, params) {

        let url = BASE_URI + "Asset/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listAssets(access_token, body) {

        let url = BASE_URI + "Asset/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createAsset(access_token, body) {

        let url = BASE_URI + "Asset/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editAsset(access_token, body) {

        let url = BASE_URI + "Asset/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteAsset(access_token, body) {

        let url = BASE_URI + "Asset/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }
    
    async setHealthStatus(access_token, body) {

        let url = BASE_URI + "Asset/setHealthStatus";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region Areas

    async getArea(access_token, params) {

        let url = BASE_URI + "Area/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listAreas(access_token, body) {

        let url = BASE_URI + "Area/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createArea(access_token, body) {

        let url = BASE_URI + "Area/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editArea(access_token, body) {

        let url = BASE_URI + "Area/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteArea(access_token, body) {

        let url = BASE_URI + "Area/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region Audit

    async getAudit(access_token, params) {

        let url = BASE_URI + "Audit/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listAudits(access_token, body) {

        let url = BASE_URI + "Audit/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createAudit(access_token, body) {

        let url = BASE_URI + "Audit/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editAudit(access_token, body) {

        let url = BASE_URI + "Audit/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async sendAudit(access_token, body) {

        let url = BASE_URI + "Audit/send";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async discardAudit(access_token, body) {

        let url = BASE_URI + "Audit/discard";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async closeAudit(access_token, body) {
        let url = BASE_URI + "Audit/close";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteAudit(access_token, body) {

        let url = BASE_URI + "Audit/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region PettyCash

    async getPettyCash(access_token, params) {

        let url = BASE_URI + "PettyCash/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listPettyCashes(access_token, body) {

        let url = BASE_URI + "PettyCash/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createPettyCash(access_token, body) {

        let url = BASE_URI + "PettyCash/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editPettyCash(access_token, body) {

        let url = BASE_URI + "PettyCash/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deletePettyCash(access_token, body) {

        let url = BASE_URI + "PettyCash/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listMovements(access_token, body) {

        let url = BASE_URI + "PettyCash/movements";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async addMovement(access_token, body) {

        let url = BASE_URI + "PettyCash/addMovement";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteMovement(access_token, body) {

        let url = BASE_URI + "PettyCash/deleteMovement";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region FunctionalUnit

    async getFunctionalUnit(access_token, params) {

        let url = BASE_URI + "FunctionalUnit/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listFunctionalUnits(access_token, body) {

        let url = BASE_URI + "FunctionalUnit/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createFunctionalUnit(access_token, body) {

        let url = BASE_URI + "FunctionalUnit/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editFunctionalUnit(access_token, body) {

        let url = BASE_URI + "FunctionalUnit/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteFunctionalUnit(access_token, body) {

        let url = BASE_URI + "FunctionalUnit/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region Room

    async getRoom(access_token, params) {

        let url = BASE_URI + "Room/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listRooms(access_token, body) {

        let url = BASE_URI + "Room/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createRoom(access_token, body) {

        let url = BASE_URI + "Room/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editRoom(access_token, body) {

        let url = BASE_URI + "Room/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteRoom(access_token, body) {

        let url = BASE_URI + "Room/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region BuildingSite

    async getBuildingSite(access_token, params) {

        let url = BASE_URI + "BuildingSite/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listBuildingSites(access_token, body) {

        let url = BASE_URI + "BuildingSite/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createBuildingSite(access_token, body) {

        let url = BASE_URI + "BuildingSite/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editBuildingSite(access_token, body) {

        let url = BASE_URI + "BuildingSite/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteBuildingSite(access_token, body) {

        let url = BASE_URI + "BuildingSite/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region Supplier

    async getSupplier(access_token, params) {

        let url = BASE_URI + "Supplier/get?tenantId=" + params.tenantId + "&id=" + params.id

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listSuppliers(access_token, body) {

        let url = BASE_URI + "Supplier/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async createSupplier(access_token, body) {

        let url = BASE_URI + "Supplier/create";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editSupplier(access_token, body) {

        let url = BASE_URI + "Supplier/edit";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteSupplier(access_token, body) {

        let url = BASE_URI + "Supplier/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

    //#region Upload File

    async uploadFile(access_token, body) {

        let url = BASE_URI + "file/upload";
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: body,
        };
        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }
    
    async addLink(access_token, body) {
console.log("addLink", body);
        let url = BASE_URI + "file/addLink";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };
        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async listFiles(access_token, body) {

        let url = BASE_URI + "file/list";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async deleteFile(access_token, body) {

        let url = BASE_URI + "file/delete";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    async editFileName(access_token, body) {

        let url = BASE_URI + "file/editFileName";

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${access_token}`);
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(body),
        };

        const query = await fetchWithTimeout(url, requestOptions);

        return query;
    }

    //#endregion

}

export default new Api();

const fetchWithTimeout = async (url, options, timeout = TIMEOUT) => {

    const query = await Promise.race([
        fetch(url, options),
        new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
    ]);

    const data = await query.json();

    if (!query.ok) {
        throw Error(data.Message === undefined ? data.message : data.Message);
    }

    return data;
}