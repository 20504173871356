import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { PURGE } from "redux-persist";

const initialState = {
    dataCount: null,
    dataCountFiltered: null,
    data: null,
    tasksData: null,
    serviceTasksData: null,
    isFetching: false,
    error: false,
    errorMessage: "",
    status: ""
}

export const GetDashboard = createAsyncThunk('dashboard/fetchDashboard', async ({authToken, body}) => {
    let response = await api.getDashboard(authToken, body);
    return response;
});

export const GetDashboardTasksData = createAsyncThunk('dashboard/fetchGetDashboardTasksData', async ({ authToken, body }) => {
    let response = await api.getDashboardTasksData(authToken, body);
    return response;
});

export const GetDashboardServiceTasksData = createAsyncThunk('dashboard/fetchGetDashboardServiceTasksData', async ({ authToken, body }) => {
    let response = await api.getDashboardServiceTasksData(authToken, body);
    return response;
});

export const GetHideDashboardServiceTasksData = createAsyncThunk('dashboard/fetchGetHideDashboardServiceTasksData', async ({ authToken, body }) => {
    let response = await api.getDashboardServiceTasksData(authToken, body);
    return response;
});


const maintenanceTaskSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {

    },
    extraReducers: {

        [GetDashboard.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetDashboard.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.data = payload.data;
            state.dataCount = payload.count;
            state.dataCountFiltered = payload.countFiltered;
        },
        [GetDashboard.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [GetDashboardTasksData.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetDashboardTasksData.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.tasksData = payload.data;
            //state.dataCount = payload.count;
            //state.dataCountFiltered = payload.countFiltered;
        },
        [GetDashboardTasksData.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [GetDashboardServiceTasksData.pending]: (state, action) => {
            state.isFetching = true;
            state.error = false;
            state.errorMessage = '';
        },
        [GetDashboardServiceTasksData.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.serviceTasksData = payload.data;
            //state.dataCount = payload.count;
            //state.dataCountFiltered = payload.countFiltered;
        },
        [GetDashboardServiceTasksData.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [GetHideDashboardServiceTasksData.pending]: (state, action) => {
            state.isFetching = false; //Hide the loader
            state.error = false;
            state.errorMessage = '';
        },
        [GetHideDashboardServiceTasksData.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.error = false;
            state.errorMessage = '';
            state.serviceTasksData = payload.data;
            //state.dataCount = payload.count;
            //state.dataCountFiltered = payload.countFiltered;
        },
        [GetHideDashboardServiceTasksData.rejected]: (state, action) => {
            state.isFetching = false;
            state.error = true;
            state.errorMessage = action.error.message;
        },

        [PURGE]: (state) => {
            return initialState;
        },
    }
})

export default maintenanceTaskSlice.reducer; 