import React from "react";
import ReactGA from "react-ga4";

import Login from "../pages/user/login.js";
import Logout from "../pages/user/logout.js";

import Dashboard from "./../pages/dashboard/dashboard.js";

import MaintenanceTaskCreate from "../pages/app/task/create.js";
import MaintenanceTaskDashboard from "../pages/app/task/dashboard.js";
import MaintenanceTaskDetail from "../pages/app/task/detail.js";
import MaintenanceTaskEdit from "../pages/app/task/edit.js";
import MaintenanceTaskList from "../pages/app/task/list.js";

import ServiceTaskCreate from "../pages/app/serviceTask/create.js";
import ServiceTaskDashboard from "../pages/app/serviceTask/dashboard.js";
import ServiceTaskDetail from "../pages/app/serviceTask/detail.js";
import ServiceTaskEdit from "../pages/app/serviceTask/edit.js";
import ServiceTaskList from "../pages/app/serviceTask/list.js";

import AssetCreate from "../pages/app/asset/create.js";
import AssetDetail from "../pages/app/asset/detail.js";
import AssetEdit from "../pages/app/asset/edit.js";
import AssetList from "../pages/app/asset/list.js";

import AreaCreate from "../pages/app/area/create.js";
import AreaDetail from "../pages/app/area/detail.js";
import AreaEdit from "../pages/app/area/edit.js";
import AreaList from "../pages/app/area/list.js";

import WorkOrderCreate from "../pages/app/workorder/create.js";
import WorkOrderDetail from "../pages/app/workorder/detail.js";
import WorkOrderList from "../pages/app/workorder/list.js";

// import ServiceWorkOrderCreate from "../pages/app/serviceWorkorder/create.js";
import ServiceWorkOrderDetail from "../pages/app/serviceWorkorder/detail.js";
// import ServiceWorkOrderList from "../pages/app/serviceWorkorder/list.js";

import SupplierCreate from "../pages/app/supplier/create.js";
import SupplierDetail from "../pages/app/supplier/detail.js";
import SupplierEdit from "../pages/app/supplier/edit.js";
import SupplierList from "../pages/app/supplier/list.js";

import Account from "../pages/app/account/account.js";
import TenantInfo from "../pages/app/account/settings/tenantInfo.js";
import UserCreate from "../pages/app/account/user/create.js";
import UserEdit from "../pages/app/account/user/edit.js";
import UserList from "../pages/app/account/user/list.js";

import LibraryList from "../pages/app/library/list.js";

import AuditCreate from "../pages/app/audit/create.js";
import AuditDetail from "../pages/app/audit/detail.js";
import AuditEdit from "../pages/app/audit/edit.js";
import AuditList from "../pages/app/audit/list.js";

import TaskReviewCreate from "../pages/app/taskReview/create.js";
import TaskReviewDetail from "../pages/app/taskReview/detail.js";
import TaskReviewEdit from "../pages/app/taskReview/edit.js";
import TaskReviewList from "../pages/app/taskReview/list.js";

import BuildingSiteCreate from "../pages/app/buildingSite/create.js";
import BuildingSiteDetail from "../pages/app/buildingSite/detail.js";
import BuildingSiteEdit from "../pages/app/buildingSite/edit.js";
import BuildingSiteList from "../pages/app/buildingSite/list.js";

import FunctionalUnitCreate from "../pages/app/functionalUnit/create.js";
import FunctionalUnitDetail from "../pages/app/functionalUnit/detail.js";
import FunctionalUnitEdit from "../pages/app/functionalUnit/edit.js";
import FunctionalUnitList from "../pages/app/functionalUnit/list.js";

import RoomCreate from "../pages/app/room/create.js";
import RoomDetail from "../pages/app/room/detail.js";
import RoomEdit from "../pages/app/room/edit.js";
import RoomList from "../pages/app/room/list.js";

import PettyCashDashboard from "../pages/app/pettyCash/dashboard.js";

import ForgotPassword from "../pages/user/forgotPassword.js";
import Register from "../pages/user/register.js";
import ResetPassword from "../pages/user/resetPassword.js";

import Buttons from "../pages/ui/ui-buttons.js";
import General from "../pages/ui/ui-general.js";
import Icons from "../pages/ui/ui-icons.js";

import WorkOrderPdf from "../pages/app/workorder/pdf.js";
import TenantDashboard from "./../pages/backend/tenant/dashboard.js";


ReactGA._gaCommandSendPageview(window.location.pathname + window.location.search);
// ReactGA._gaCommandSendEvent("userClick", user?.selectedTenant?.userName);
ReactGA._gaCommandSendEvent("userClickPath", window.location.pathname + window.location.search);

const routes = [
  // {
  //   path: "/",
  //   exact: true,
  //   component: () => <Dashboard />,
  // },
  {
    path: "/ui/general",
    title: "General",
    component: () => <General />,
  },
  {
    path: "/ui/icons",
    title: "Icons",
    component: () => <Icons />,
  },
  {
    path: "/ui/buttons",
    title: "Buttons",
    component: () => <Buttons />,
  },
  {
    path: "/user/login",
    title: "Login",
    component: () => <Login />,
  },
  {
    path: "/user/logout",
    title: "Logout",
    component: () => <Logout />,
  },
  {
    path: "/help",
    roles: "", //Sino tiene roles asigandos es acceso a todos
    title: "Help",
    component: () => <Dashboard />,
  },
  {
    path: "/",
    roles: "", //Sino tiene roles asigandos es acceso a todos
    title: "Dashboard",
    exact: true,
    component: () => <Dashboard />,
  },
  {
    path: "/dashboard",
    roles: "", //Sino tiene roles asigandos es acceso a todos
    title: "Dashboard",
    component: () => <Dashboard />,
  },

  {
    path: "/task/dashboard",
    roles: "MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Tasks",
    component: () => <MaintenanceTaskDashboard />,
  },
  {
    path: "/task/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Tasks",
    component: () => <MaintenanceTaskList />,
  },
  {
    path: "/task/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Detail Task",
    component: () => <MaintenanceTaskDetail />,
  },
  {
    path: "/task/create",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF",
    title: "Create Task",
    component: () => <MaintenanceTaskCreate />,
  },
  {
    path: "/task/edit",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF",
    title: "Edit Task",
    component: () => <MaintenanceTaskEdit />,
  },

  {
    path: "/serviceTask/dashboard",
    roles: "MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, TASKIDENTIFIER",
    title: "Service Tasks",
    component: () => <ServiceTaskDashboard />,
  },
  {
    path: "/serviceTask/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, TASKIDENTIFIER",
    title: "Service Tasks",
    component: () => <ServiceTaskList />,
  },
  {
    path: "/serviceTask/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, TASKIDENTIFIER",
    title: "Detail Service Task",
    component: () => <ServiceTaskDetail />,
  },
  {
    path: "/serviceTask/create",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, TASKIDENTIFIER",
    title: "Create Service Task",
    component: () => <ServiceTaskCreate />,
  },
  {
    path: "/serviceTask/edit",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, TASKIDENTIFIER",
    title: "Edit Service Task",
    component: () => <ServiceTaskEdit />,
  },

  {
    path: "/asset/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MxxxxxxxxxxAINTENANCEOperator",
    title: "Assets List",
    component: () => <AssetList />,
  },
  {
    path: "/asset/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Asset Detail",
    component: () => <AssetDetail />,
  },
  {
    path: "/asset/create",
    roles: " MANAGER, MAINTENANCECHIEF",
    title: "Create Asset",
    component: () => <AssetCreate />,
  },
  {
    path: "/asset/edit",
    roles: " MANAGER, MAINTENANCECHIEF",
    title: "Edit Asset",
    component: () => <AssetEdit />,
  },

  {
    path: "/workorder/detailPdf",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Detail Work Order",
    component: () => <WorkOrderPdf />,
  },
  
  {
    path: "/workorder/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Work Orders List",
    component: () => <WorkOrderList />,
  },
  {
    path: "/workorder/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Detail Work Order",
    component: () => <WorkOrderDetail />,
  },
  {
    path: "/workorder/create",
    roles: " MANAGER, MAINTENANCECHIEF",
    title: "Detail Work Order",
    component: () => <WorkOrderCreate />,
  },

  // {
  //   path: "/serviceworkorder/list",
  //   roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
  //   title: "Work Orders List",
  //   component: () => <ServiceWorkOrderList />,
  // },
  {
    path: "/serviceworkorder/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Detail Work Order",
    component: () => <ServiceWorkOrderDetail />,
  },
  // {
  //   path: "/serviceworkorder/create",
  //   roles: " MANAGER, MAINTENANCECHIEF",
  //   title: "Detail Work Order",
  //   component: () => <ServiceWorkOrderCreate />,
  // },

  {
    path: "/area/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MxxxxxxxxAINTENANCEOperator, ADMINISTRATOR",
    title: "Area List",
    component: () => <AreaList />,
  },
  {
    path: "/area/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, ADMINISTRATOR",
    title: "Area Detail",
    component: () => <AreaDetail />,
  },
  {
    path: "/area/create",
    roles: " MANAGER, ADMINISTRATOR",
    title: "Create Area",
    component: () => <AreaCreate />,
  },
  {
    path: "/area/edit",
    roles: " MANAGER, ADMINISTRATOR",
    title: "Edit Area",
    component: () => <AreaEdit />,
  },
  ...(true
    ? [
      {
        path: "/audit/list",
        roles: " MANAGER, Auditor",
        title: "Audits List",
        component: () => <AuditList />,
      },
    ]
    : []),
  ...(true
    ? [
      {
        path: "/audit/detail",
        roles: "MANAGER, Auditor",
        title: "Audit Detail",
        component: () => <AuditDetail />,
      },
    ]
    : []),
  ...(true
    ? [
      {
        path: "/audit/create",
        roles: "MANAGER, Auditor",
        title: "Create Audit",
        component: () => <AuditCreate />,
      },
    ]
    : []),
  ...(true
    ? [
      {
        path: "/audit/edit",
        roles: "MANAGER, Auditor",
        title: "Edit Audit",
        component: () => <AuditEdit />,
      },
    ]
    : []),

  {
    path: "/taskreview/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, ADMINISTRATOR, TASKIDENTIFIER",
    title: "Area  Task Review",
    component: () => <TaskReviewList />,
  },
  {
    path: "/taskreview/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, ADMINISTRATOR, TASKIDENTIFIER",
    title: "Area  Task Review",
    component: () => <TaskReviewDetail />,
  },
  {
    path: "/taskreview/create",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, ADMINISTRATOR, TASKIDENTIFIER",
    title: "Create  Task Review",
    component: () => <TaskReviewCreate />,
  },
  {
    path: "/taskreview/edit",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, ADMINISTRATOR, TASKIDENTIFIER",
    title: "Edit Task Review",
    component: () => <TaskReviewEdit />,
  },

  {
    path: "/buildingSite/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Building Sites List",
    component: () => <BuildingSiteList />,
  },
  {
    path: "/buildingSite/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Building Site Detail",
    component: () => <BuildingSiteDetail />,
  },
  {
    path: "/buildingSite/create",
    roles: " MANAGER",
    title: "Create Building Site",
    component: () => <BuildingSiteCreate />,
  },
  {
    path: "/buildingSite/edit",
    roles: " MANAGER",
    title: "Edit Building Site",
    component: () => <BuildingSiteEdit />,
  },

  {
    path: "/functionalUnit/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Functional Unit List",
    component: () => <FunctionalUnitList />,
  },
  {
    path: "/functionalUnit/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, TASKIDENTIFIER",
    title: "Functional Unit Detail",
    component: () => <FunctionalUnitDetail />,
  },
  {
    path: "/functionalUnit/create",
    roles: " MANAGER",
    title: "Create Functional Unit",
    component: () => <FunctionalUnitCreate />,
  },
  {
    path: "/functionalUnit/edit",
    roles: " MANAGER",
    title: "Edit Functional Unit",
    component: () => <FunctionalUnitEdit />,
  },

  {
    path: "/room/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MxxxxxxxxxxAINTENANCEOperator",
    title: "Room List",
    component: () => <RoomList />,
  },
  {
    path: "/room/detail",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator, TASKIDENTIFIER",
    title: "Room Detail",
    component: () => <RoomDetail />,
  },
  {
    path: "/room/create",
    roles: " MANAGER",
    title: "Create Room",
    component: () => <RoomCreate />,
  },
  {
    path: "/room/edit",
    roles: " MANAGER",
    title: "Edit Room",
    component: () => <RoomEdit />,
  },

  {
    path: "/supplier/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF",
    title: "Proveedores",
    component: () => <SupplierList />,
  },
  {
    path: "/supplier/detail",
    roles: "MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Detalle Proveedor",
    component: () => <SupplierDetail />,
  },
  {
    path: "/supplier/create",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF",
    title: "Nuevo Proveedor",
    component: () => <SupplierCreate />,
  },
  {
    path: "/supplier/edit",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF",
    title: "Editar Proveedor",
    component: () => <SupplierEdit />,
  },
  {
    path: "/library/list",
    roles: " MANAGER, COUNCILOR, MAINTENANCECHIEF, MAINTENANCEOperator",
    title: "Library List",
    component: () => <LibraryList />,
  },

  {
    path: "/account",
    roles: " Administrator, MANAGER",
    title: "Cuenta",
    component: () => <Account />,
  },
  {
    path: "/settings/tenantinfo",
    roles: " Administrator, MANAGER",
    title: "Account Info",
    component: () => <TenantInfo />,
  },
  {
    path: "/user/list",
    roles: " Administrator, MANAGER",
    title: "Users List",
    component: () => <UserList />,
  },
  {
    path: "/user/create",
    roles: " Administrator, MANAGER",
    title: "Create User",
    component: () => <UserCreate />,
  },
  {
    path: "/user/edit",
    roles: " Administrator, MANAGER",
    title: "Edit User",
    component: () => <UserEdit />,
  },

  {
    path: "/pettyCash/dashboard",
    roles: "MANAGER, COUNCILOR",
    title: "Caja",
    component: () => <PettyCashDashboard />,
  },
  {
    path: "/user/register",
    title: "Register",
    component: () => <Register />,
  },
  {
    path: "/user/forgotPassword",
    title: "Forgot Password",
    component: () => <ForgotPassword />,
  },
  {
    path: "/user/resetPassword",
    title: "Reset Password",
    component: () => <ResetPassword />,
  },

  {
    path: "/backend/tenant/dashboard",
    roles: "ADMINISTRATOR",
    title: "Tenant Dashboard",
    component: () => <TenantDashboard />,
  }
];

export default routes;
