import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Upload from '../../../components/app/file/upload.js';
import Viewer from '../../../components/app/file/viewer.js';
import { AppSettings } from '../../../config/app-settings.js';
import { DeleteAsset, GetAsset, SetHealthStatus } from '../../../services/redux/assetSlice';
import { DeleteFile, postfileData } from '../../../services/redux/fileSlice';
import { FileTypes, WorkOrderStateFinishedId } from '../../../utils/generalConstants';
import { Loader } from '../../widget/loader.js';
import { Panel, PanelBody, PanelFooter, PanelHeader } from '.././../../components/panel/panel.jsx';

const AssetDetail = () => {
    const dispatch = useDispatch();
    const context = useContext(AppSettings);
    const history = useHistory();
    const { t } = useTranslation('common');

    const user = useSelector(state => state.userReducer);
    const asset = useSelector(state => state.assetReducer)
    const file = useSelector(state => state.fileReducer)

    const query = new URLSearchParams(window.location.search)

    const [modalAddFiles, setModalAddFiles] = useState(false);
    const [modalViewDeleteFile, setModalViewDeleteFile] = useState(false);
    const [modalViewDeleteAsset, setModalViewDeleteAsset] = useState(false);

    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState('Cargando...');

    const getAssetLocal = async () => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": query.get("id")
        };
        await dispatch(GetAsset({ authToken: user.authToken, params: requestData })).then((response) => {
        });
    };

    const deleteAsset = async (id) => {
        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": id,
        }
        await dispatch(DeleteAsset({ authToken: user.authToken, body: requestData })).then((response) => {
            response && response.payload &&
                history.push("/asset/list");
        });
    }

    const deleteFile = async (fileId) => {
        const requestDataDeleteFile = {
            "tenantId": user.selectedTenant.id,
            "id": fileId
        };
        console.log(requestDataDeleteFile);
        await dispatch(DeleteFile({ authToken: user.authToken, body: requestDataDeleteFile })).then((response) => {
            console.log(response);
            getAssetLocal();
        });
    };

    const onUploadSubmit = async (data) => {
        data.append("TenantId", user.selectedTenant.id);
        data.append("AgentId", user.selectedTenant.agentId);
        data.append("CreatedOn", "2015-08-05T19:33:26Z");
        data.append("ReferenceId", asset.selectedAsset.id);
        data.append("FileTypeId", FileTypes.filter(f => f.NormalizeName === 'ASSET IMAGE')[0].id);

        await dispatch(postfileData({ authToken: user.authToken, body: data })).then((response) => {
            setModalAddFiles(false);
            getAssetLocal();
        });
    };

    const setHealthStatus = async (status) => {
        if (asset.selectedAsset.healthStatus === status) return;

        let requestData = {
            "tenantId": user.selectedTenant.id,
            "id": asset.selectedAsset.id,
            "healthStatus": status
        };
        await dispatch(SetHealthStatus({ authToken: user.authToken, body: requestData })).then((response) => {
        });
    };

    useEffect(() => {
        context.handleSetAppSidebarNone(false);
        context.handleSetAppHeaderNone(false);
        getAssetLocal();
    }, []);

    const prompthRef = React.createRef();

    // useEffect(() => {
    //     if (asset.selectedAsset) {

    //         let name = asset.selectedAsset.name;
    //         let model = asset.selectedAsset.model;
    //         let prompt = "";

    //         if (name && model) {
    //             prompt = "Mantenimiento recomendado para " + name + " " + model;
    //         } else if (name) {
    //             prompt = "Mantenimiento recomendado para " + name;
    //         } else if (model) {
    //             prompt = "Mantenimiento recomendado para " + model;
    //         }

    //         setPrompt(prompt);
    //         prompthRef.current = prompt;

    //         //timeout de un 2 segundo antes de llamar a callChatGPT
    //         callChatGPT();

    //     }
    // }, [asset.selectedAsset]);

    const toggleModal = (name) => {
        switch (name) {
            case 'modalAddFiles':
                setModalAddFiles(!modalAddFiles);
                break;
            case 'modalViewDeleteFile':
                setModalViewDeleteFile(!modalViewDeleteFile);
                break;
            case 'modalViewDeleteAsset':
                setModalViewDeleteAsset(!modalViewDeleteAsset);
                break;
            default:
                break;
        }
    }

    const closeModal = () => {
        setModalAddFiles(false);
        getAssetLocal();
    }

    if (user.authToken == "") {
        return <Redirect to='/user/login' />;
    }



    const callChatGPT = async () => {
        try {
            //const response = await getOpenAIResponse(prompthRef.current);
            // console.log("response", response);
            setResponse(response);

        } catch (error) {
            console.error('Error al llamar a la API de ChatGPT', error);
        }
    };

    const allowEdit = (user.selectedTenant.agentRoles.toUpperCase() == "MANAGER" ||
        user.selectedTenant.agentRoles.toUpperCase() == "MAINTENANCECHIEF");

    return (
        <Loader isLoading={asset.isFetching || file.isFetching}>
            <div>
                {asset.selectedAsset &&
                    <div>
                        <ol className="breadcrumb float-xl-end" hidden>
                            <li className="breadcrumb-item"><Link to="/dashboard">Inicio</Link></li>
                            <li className="breadcrumb-item"><Link to="/asset/list">Activos</Link></li>
                            <li className="breadcrumb-item active">{asset.selectedAsset.name}</li>
                        </ol>
                        <h1 className="page-header">{asset.selectedAsset.name}</h1>

                        <div className="d-flex align-items-center mb-2">
                            <div className="ms-auto">
                                <Link to="/asset/list" className="btn btn-success btn-sm btn-rounded px-3 rounded-pill ms-5px d-print-none"><i className="fa fa-magnifying-glass me-1"></i> {t("menu.tasks_list")}</Link>
                                <Link to={"/task/create?assetId=" + asset.selectedAsset.id} className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px d-print-none"><i className="fa fa-plus me-1"></i> {t("common.add")} {t("common.task")}</Link>
                                {/* <Link to="/asset/create" className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-5px d-print-none"><i className="fa fa-plus me-1"></i> {t("common.add")}</Link> */}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-6">
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex justify-content-between">
                                                {t("common.general_data")}
                                                {allowEdit &&
                                                    <div>
                                                        <Link className="btn btn-success btn-sm btn-rounded px-3 rounded-pill d-print-none" to={{
                                                            pathname: "/asset/edit",
                                                            state: {
                                                                asset: asset.selectedAsset
                                                            }
                                                        }}> {t("common.edit")}</Link>
                                                        <button
                                                            className="btn btn-green btn-sm btn-rounded px-3 rounded-pill ms-2 d-print-none"
                                                            onClick={() => toggleModal('modalAddFiles')}>
                                                            {t("common.add_files")}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            {asset.selectedAsset.brand &&
                                                <p>{t("common.brand")}: {asset.selectedAsset.brand}</p>
                                            }
                                            {asset.selectedAsset.model &&
                                                <p>{t("common.model")}: {asset.selectedAsset.model}</p>
                                            }
                                            {asset.selectedAsset.serialNumber &&
                                                <p>{t("common.serial_number")}: {asset.selectedAsset.serialNumber}</p>
                                            }
                                            {/* <p>isActive: {asset.selectedAsset.isActive ? "Si" : "No"}</p> */}

                                            {/*
                                            <p>{t("asset.out_of_service")}: {asset.selectedAsset.isOutOfService ? "Si" : "No"}</p>
                                            <p>{t("asset.backup")}: {asset.selectedAsset.isBackup ? "Si" : "No"}</p>
                                            <p>{t("common.type")}: <span className="badge bg-default text-dark me-5px">{t("asset.type_" + asset.selectedAsset.typeNormalizedName)}</span></p>
                                            */}

                                            <p>
                                                <span className="badge bg-default text-dark me-5px">{t("asset.type_" + asset.selectedAsset.typeNormalizedName)}</span>
                                                {!asset.selectedAsset.isOutOfService && !asset.selectedAsset.isBackup && !asset.selectedAsset.isArchived && <span className="badge bg-green me-5px">{t("asset.in_service")}</span>}
                                                {asset.selectedAsset.isOutOfService && <span className="badge bg-red me-5px">{t("asset.out_of_service")}</span>}
                                                {asset.selectedAsset.isBackup && <span className="badge bg-blue me-5px">{t("asset.backup")}</span>}
                                                {asset.selectedAsset.isArchived && <span className="badge bg-red me-5px">{t("asset.archived")}</span>}
                                            </p>

                                            <p>{t("common.priority")}: {t("common.priority_level_" + asset.selectedAsset.priorityLevelNormalizedName)}</p>
                                            {asset.selectedAsset.areaName &&
                                                // <p>{t("common.area")}: {asset.selectedAsset.areaName}</p>
                                                <p>{t("common.area")}: {asset.selectedAsset.areaName}&nbsp;<Link to={"/area/detail?id=" + asset.selectedAsset.areaId + "&assetid=" + asset.selectedAsset.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px d-print-none">{t("common.area")}</Link></p>
                                            }
                                            {asset.selectedAsset.roomName &&
                                                <p>{t("common.room")}: {asset.selectedAsset.roomName}&nbsp;<Link to={"/room/detail?id=" + asset.selectedAsset.roomId + "&assetid=" + asset.selectedAsset.id} className="btn btn-info btn-sm btn-rounded px-3 rounded-pill ms-5px d-print-none">{t("common.room")}</Link></p>
                                            }
                                            {asset.selectedAsset.purchaseDate &&
                                                <p>{t("common.purchase_date")}: {moment(asset.selectedAsset.purchaseDate).format("DD/MM/YYYY")}</p>
                                            }
                                            {asset.selectedAsset.warrantyExpirationDate &&
                                                <p>{t("asset.warranty_expiration_date")}: {moment(asset.selectedAsset.warrantyExpirationDate).format("DD/MM/YYYY")}</p>
                                            }
                                            {((user?.selectedTenant?.userName === "emenoguera@gmail.com" || user?.selectedTenant?.userName === "asistente.wartun@gmail.com") &&
                                                allowEdit) &&
                                                <p>
                                                    {t("common.health_status")}:
                                                    <span onClick={() => setHealthStatus(1)} className={asset.selectedAsset.healthStatus === 1 ? "badge bg-green ms-5px me-5px opacity-100" : "badge bg-green ms-5px me-5px opacity-30 cursor-pointer"}>{t("common.health_status_GOOD")}</span>
                                                    <span onClick={() => setHealthStatus(2)} className={asset.selectedAsset.healthStatus === 2 ? "badge bg-yellow me-5px text-black opacity-100" : "badge bg-yellow me-5px text-black opacity-30 cursor-pointer"}>{t("common.health_status_REGULAR")}</span>
                                                    <span onClick={() => setHealthStatus(3)} className={asset.selectedAsset.healthStatus === 3 ? "badge bg-red me-5px opacity-100" : "badge bg-red me-5px opacity-30 cursor-pointer"}>{t("common.health_status_BAD")}</span>
                                                </p>
                                            }
                                            {asset.selectedAsset.moreInfo &&
                                                <p>{t("common.aditional_info")}:<br />{asset.selectedAsset.moreInfo}</p>
                                            }
                                        </PanelBody>
                                        {!(asset.selectedAsset.workOrders || asset.selectedAsset.maintenanceTasks) &&
                                            <PanelFooter>
                                                <div className="d-flex align-items-center">
                                                    <div className="ms-auto">
                                                        {query.get("taskid") &&
                                                            <Link to={"/task/detail?id=" + query.get("taskid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                        }
                                                        {query.get("workorderid") &&
                                                            <Link to={"/workorder/detail?id=" + query.get("workorderid")} className="btn btn-primary btn-sm btn-rounded px-3 rounded-pill me-5px"><i className="fa fa-backward me-1"></i> {t("common.go_back")}</Link>
                                                        }
                                                        {allowEdit &&
                                                            <button className='btn btn-danger' onClick={() => { toggleModal('modalViewDeleteAsset') }}> {t("common.delete")} <i className="fa fa-trash"></i> </button>
                                                        }
                                                    </div>
                                                </div>
                                            </PanelFooter>
                                        }
                                    </Panel>
                                </div>
                            </div>
                            {(asset.selectedAsset.workOrders || asset.selectedAsset.maintenanceTasks) &&
                                <div className="col-xl-3">
                                    {asset.selectedAsset.maintenanceTasks && asset.selectedAsset.maintenanceTasks.length != 0 &&
                                        <div className="col-xl-12">
                                            <Panel>
                                                <PanelHeader noButton={true}>
                                                    <div className="d-flex align-items-center">
                                                        {t('task.scheduled_tasks_title')}
                                                    </div>
                                                </PanelHeader>
                                                <PanelBody>
                                                    {asset.selectedAsset.maintenanceTasks.map((task, index) =>
                                                        <div key={index}>
                                                            <p><i className="fa fa-tasks"></i> {task.description}</p>
                                                            <p>
                                                                <Link to={"/task/detail?id=" + task.id} key={index}>
                                                                    {moment(task.scheduledDate).format("DD/MM/YYYY")}<span className="badge bg-gray ms-8px">{t("task.state_" + task.stateNormalizedName)}</span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    )}
                                                    {asset.selectedAsset.maintenanceTasks.length === 0 &&
                                                        <p>{t('task.scheduled_tasks_title')} - {t('common.no_records_found')}</p>
                                                    }
                                                </PanelBody>
                                            </Panel>
                                        </div>
                                    }
                                    {asset.selectedAsset.workOrders && asset.selectedAsset.workOrders.filter(w => w.stateId != WorkOrderStateFinishedId).length != 0 &&
                                        <div className="col-xl-12">
                                            <Panel>
                                                <PanelHeader noButton={true}>
                                                    <div className="d-flex align-items-center">
                                                        {t('dashboard.title_tasks_active')}
                                                    </div>
                                                </PanelHeader>
                                                <PanelBody>
                                                    {asset.selectedAsset.workOrders.filter(w => w.stateId != WorkOrderStateFinishedId).map((workorder, index) =>
                                                        <div key={index}>
                                                            <p><i className="fa fa-tasks"></i> {workorder.description}</p>
                                                            <p>
                                                                <Link to={"/workorder/detail?id=" + workorder.id} key={index} >
                                                                    {moment(workorder.finishedOn ? workorder.finishedOn : workorder.startedOn).format("DD/MM/YYYY")}<span className="badge bg-gray ms-8px">{t("workOrder.state_" + workorder.stateNormalizedName)}</span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    )}
                                                    {asset.selectedAsset.workOrders.length === 0 &&
                                                        <p>{t('dashboard.title_tasks_active')} - {t('common.no_records_found')}</p>
                                                    }
                                                </PanelBody>
                                            </Panel>
                                        </div>
                                    }
                                    {asset.selectedAsset.workOrders && asset.selectedAsset.workOrders.filter(w => w.stateId === WorkOrderStateFinishedId).length != 0 &&
                                        <div className="col-xl-12">
                                            <Panel>
                                                <PanelHeader noButton={true}>
                                                    <div className="d-flex align-items-center">
                                                        {t('dashboard.title_tasks_finished')}
                                                    </div>
                                                </PanelHeader>
                                                <PanelBody>
                                                    {asset.selectedAsset.workOrders.filter(w => w.stateId === WorkOrderStateFinishedId).map((workorder, index) =>
                                                        <div key={index}>
                                                            <p><i className="fa fa-tasks"></i> {workorder.description}</p>
                                                            <p>
                                                                <Link to={"/workorder/detail?id=" + workorder.id} key={index} >
                                                                    {moment(workorder.finishedOn ? workorder.finishedOn : workorder.startedOn).format("DD/MM/YYYY")}<span className="badge bg-gray ms-8px">{t("workOrder.state_" + workorder.stateNormalizedName)}</span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    )}
                                                    {asset.selectedAsset.workOrders.length === 0 &&
                                                        <p>{t('dashboard.title_tasks_finished')} - {t('common.no_records_found')}</p>
                                                    }
                                                </PanelBody>
                                            </Panel>
                                        </div>
                                    }
                                </div>
                            }
                            {asset.selectedAsset.logTracks && asset.selectedAsset.logTracks.length != 0 &&
                                <div className="col-xl-3">
                                    <div className="col-xl-12">
                                        <Panel>
                                            <PanelHeader noButton={true}>
                                                <div className="d-flex align-items-center">
                                                    Historial
                                                </div>
                                            </PanelHeader>
                                            <PanelBody>
                                                {asset.selectedAsset.logTracks.map((log, index) =>
                                                    <div key={index}>
                                                        <p>{moment(log.createdOn).format("DD/MM/YYYY")}&nbsp;{log.CreatedByFullName}</p>
                                                        <p><i className="fa fa-circle-info"></i> {log.body}</p>
                                                    </div>
                                                )}
                                                {asset.selectedAsset.logTracks.length === 0 &&
                                                    <p>No se registran historial de acciones para este activo.</p>
                                                }
                                            </PanelBody>
                                        </Panel>
                                    </div>
                                </div>
                            }
                            <div className="col-xl-3" hidden>
                                <div className="col-xl-12">
                                    <Panel>
                                        <PanelHeader noButton={true}>
                                            <div className="d-flex align-items-center">
                                                Wartun AI
                                            </div>
                                        </PanelHeader>
                                        <PanelBody>
                                            {prompt}
                                            <br></br>
                                            <br></br>
                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                {response}
                                            </div>
                                        </PanelBody>
                                        {/* <PanelFooter>
                                        <div className="d-flex align-items-center">
                                            <div className="ms-auto">
                                                <button className='btn btn-primary' onClick={() => { callChatGPT() }}> {t("common.send")} <i className="fa fa-submit"></i> </button>
                                            </div>
                                        </div>
                                    </PanelFooter> */}
                                    </Panel>
                                </div>
                            </div>
                        </div>
                        <Upload onSubmit={onUploadSubmit} onClose={closeModal} fileTypeId={FileTypes.filter(f => f.NormalizeName === 'ASSET IMAGE')[0].id} referenceId={asset.selectedAsset.id} isOpen={modalAddFiles} toggleModal={() => toggleModal('modalAddFiles')}  ></Upload>

                        <Viewer files={asset.selectedAsset.files} allowDelete={allowEdit} deleteAsyncCallback={deleteFile} />

                        <Modal size="md" style={{ maxWidth: '300px', width: '100%' }} isOpen={modalViewDeleteAsset} toggle={() => toggleModal('modalViewDeleteAsset')} >
                            <ModalHeader toggle={() => toggleModal('modalViewDeleteAsset')}>Eliminar Activo</ModalHeader>
                            <ModalBody>
                                {!asset.error ?
                                    <span>¿Esta seguro que desea eliminar el activo <b>{asset.selectedAsset.name}</b>, creado por <b>{asset.selectedAsset.createdBy}</b> el <b>{moment(asset.selectedAsset.createdOn).format("DD/MM/YYYY")}</b> ?</span>
                                    :
                                    <div className='text-center'>
                                        <span className='text-danger'>Ha ocurrido un problema: {asset.errorMessage}</span></div>}
                            </ModalBody>
                            {!asset.error &&
                                <ModalFooter>
                                    <button className="btn btn-danger" onClick={() => deleteAsset(asset.selectedAsset.id)}>Si, borrar!</button>
                                    <button className="btn btn-white" onClick={() => toggleModal('modalViewDeleteAsset')}>No</button>
                                </ModalFooter>
                            }
                        </Modal>

                    </div>
                }
            </div>
        </Loader>
    )
};

export default AssetDetail;